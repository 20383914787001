.login-grid{
    max-width: 450px;
    padding: 10px 20px;
}

.radio-group .error-txt {
    margin-left: -42px;
}
.flag-dropdown{
    height: 40px;
}
.react-tel-input input{
    height: 40px !important;
    width: 40px !important;

}
.otp-input input{
    margin-right: 10px !important;
}
.otp-cancel-btn{
    font-size: 12px !important;
    line-height: 16px !important;
    color: #d2d2d2 !important;
}
.react-tel-input .form-control{
    width: 100% !important;
}
.forget-password {
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 520px) {
    .otp-input input{
        margin-right: 5px !important;
    }
}
@media only screen and (max-width: 480px) {
    .otp-input input{
        width: 28px !important
    }
}

@media only screen and (max-width: 445px) {
    .otp-input input{
        width: 24px !important
    }
}

@media only screen and (max-width: 415px) {
    .otp-input input{
        width: 18px !important
    }
}