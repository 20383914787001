.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.link-txt{
  cursor: pointer;
}
.brown-sm-txt{
  font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 700 !important;
}

.asterisk-symbol{
  color: #ff0000;
  margin: 3px;
}
.field-name{
  margin: 9px 0px !important;
  font-weight: bold !important;
  font-size: 13px !important;
}

.field-value{
  font-weight: bold !important;
  font-size: 13px !important;
  color: #828181;
  word-break: break-all;
}
.preview-img{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-container {
  padding: 60px;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .form-container {
    padding: 30px;
  }
  .padding-mobile-zero {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mobile-width-100 {
    width: 100%;
  }
  .paddingMobile {
    padding: 16px !important;
  }
  .mobile-text-center {
    text-align: center !important;
  }
  .show-only-mobile {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
}

.cream-bg{
  background-color: #FFFFEF;
}
.white-bg{
  background-color: #fff !important;
}
.light-bg{
  background-color: #F2F5F5;
}
.panel-area{
  box-shadow: 3px 6px 12px 3px rgba(157, 157, 157, 1);
 
  .panel-header{
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid #d2d2d2;
  }
  .panel-body{
    padding: 30px;
    max-height: calc(100vh - 310px);
    overflow-y: auto;
  }
  .panel-footer{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 15px 30px;
  }

}

.MuiTypography-h6{
  font-weight: 600 !important;
}

.center-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 60px 12px;
  background-color: #F6F6F6;
}
.center-text{
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0px 12px;
}
.left-text{
  text-align: left;
}
.tiny-txt{
  font-size: 11px !important;
}
.flex-row{
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0px;
}
.trending-card{
  display: flex;
  justify-content: left;

}
.flex-box{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3px;
  text-align: left;
}
.no-margin{
  margin: 0px;
}
.no-padding{
  padding: 0px;
}
.green-fonts{
  color: #16A34A;
}
.red-fonts{
  color: #ff0000;
}

.MuiTypography-body1{
  font-weight: 600;
  text-align: left;
}
.error-txt{
  color: #d32f2f;
  font-size: 12px !important;
  margin: 4px 14px !important
}
.error-field{
  border: 1px solid #d32f2f !important;
  color : #d32f2f;
}
.admin-area{
  width: 100%;
  overflow-x: hidden;
  background: #ebf1f8;
  margin-top: 64px;
  overflow-x: hidden;
  padding: 30px;
  text-align: -webkit-auto;
  width: 100%;
}

.user-area{
  width: 100%;
}
.flex-box{
  display: flex;
}

.MuiDataGrid-columnHeadersInner{
  background-color: #FEF7DE;
}

.MuiDataGrid-columnHeaderTitle{
  font-weight: bold !important;
}

.MuiFormLabel-root.Mui-focused{
  color: #d2d2d2 !important;
}

.link-btn{
  font-size: 11px !important;
  margin: 6px 0 !important;
  padding: 3px 0px !important;
  color: #0E8CBF !important;
  font-weight: bold !important;

}

.empty-datagrid .MuiDataGrid-virtualScroller{
  overflow-x: hidden;
  min-height: 30px;
}

.vhCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cursor-pointer{
  cursor: pointer;
}
.doc-icon {
  height: 150px;
  width: 150px;
  border: 1px solid #d2d2d2;
  border-radius: 9px;
  margin: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .MuiAvatar-square{
      height: 50%;
      width: 50%;
  }
  p{
      color: #0E8CBF;
  }
}

.event-icon {
  height: 250px;
  width: 250px;
  border: 1px solid #d2d2d2;
  border-radius: 9px;
  margin: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .MuiAvatar-square{
      height: 75%;
      width: 75%;
      .MuiSvgIcon-root{
        width: 100%;
        height: 100%;
      }
  }
  p{
      color: #0E8CBF;
  }
}

.border-bottom{
  border-bottom: 1px solid #dddddd;
  padding-bottom: 30px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #0CABB8;
  border-radius: 9px;
}

::-webkit-scrollbar-thumb {
  background: #0CABB8;
  border-radius: 9px;
}

::-webkit-scrollbar-thumb:hover {
  background: #23CDDA;
}


@media only screen and (max-width: 1024px){
  .paddingMobile {
    padding: 16px !important;
  }
}

.swal2-container{
  z-index: 10000;
}
