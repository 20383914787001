.offlineDiv {
    position: fixed;
    z-index: 99999999999999;
    width: 100%;
    height: 100%;
    background: #0c0b0bc7;
    .notification-div {
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        height: 100%;
    }
    @media only screen and (max-width: 900px) {
        .notification-div {
            flex-direction: column;
            .MuiTypography-root {
                text-align: center;
            }
        }
    }
}
