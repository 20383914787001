.mobile-menu .MuiDivider-root {
    border-color: white !important;
}
.mobile-menu{
    padding: 0px !important;
}

.mobile-menu li{
    padding: 0 8px !important;
    font-size: 13px !important;
    text-align: left;
   
    
}

.mobile-menu li .css-10hburv-MuiTypography-root{
    font-size: 13px !important;
    text-align: left !important;
}
.mobile-notification{
    position: absolute;
    right: 50px;
}
.MuiDialogContent-root{
    padding: 0px !important;
}